import React, { useEffect, useState } from "react";
import { fetchActiveTotalData, fetchData, fetchTotalData, fetchAccounts, fetchBankAuthSuccess } from "./api/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import "./App.css";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { ListBox } from "primereact/listbox";

const App = () => {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dates, setDates] = useState([]);
  const [userActivityData, setUserActivityData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [allBankData, setAllBankData] = useState([]);
  const [totalStats, setTotalStats] = useState([]);
  const [bankAuthAccounts, setBankAuthAccounts] = useState([]);
  const [bankAuthAccountsSuccess, setBankAuthAccountsSuccess] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const responseTotal = await fetchTotalData();
        const responseActiveTotal = await fetchActiveTotalData();
        const responseAccounts = await fetchAccounts();
        const responseBankAuthSuccess = await fetchBankAuthSuccess();

        setBankAuthAccounts(responseAccounts.accounts);
        setBankAuthAccountsSuccess(responseBankAuthSuccess);

        setTotalStats([
          {
            title: "Total Users",
            count: responseTotal.total_signups,
          },
          {
            title: "Tx Added",
            count: responseTotal.total_transactions,
          },
          {
            title: "Tx Categorized",
            count: responseTotal.total_transactions_categorized,
          },
          {
            title: "Accounts added (Group)",
            count: responseTotal.total_accounts_added_group,
          },
          {
            title: "Accounts added (Personal)",
            count: responseTotal.total_accounts_added_personal,
          },
          {
            title: "Accounts Bank Auth",
            count: responseActiveTotal.active_bank_auth_count,
          },
          {
            title: "Accounts Email Auth",
            count: responseActiveTotal.active_email_auth_count,
          },
        ]);

        const response = await fetchData(offset);
        const transformedData = transformData(response);
        setTableData(transformedData.data);
        setDates(transformedData.dates);

        const transformedUserActivityData = transformUserActivityData(
          response.daily_user_activity_status
        );
        setUserActivityData(transformedUserActivityData);

        const transformedBankData = transformBankData(
          response.email_bank_activations
        );
        setBankData(transformedBankData);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    loadData();
  }, [offset]);

  // Function to transform the API response data into a suitable format for the table
  const transformData = (data) => {
    const datesSet = new Set();
    // Extract all unique dates
    data.accounts_added_group.forEach((item) => {
      Object.keys(item).forEach((date) => datesSet.add(date));
    });

    const uniqueDates = Array.from(datesSet);
    const transformed = [
      {
        stat: "Total Number of Users",
        ...data.signup_stats.reduce((acc, item) => {
          const date = Object.keys(item)[0];
          acc[date] = item[date] || 0;
          return acc;
        }, {}),
      },
      {
        stat: "Total number of Tx Added",
        ...data.transactions_added.reduce((acc, item) => {
          const date = Object.keys(item)[0];
          acc[date] = item[date] || 0;
          return acc;
        }, {}),
      },
      {
        stat: "Total number of Tx Categorized",
        ...data.transactions_categorized.reduce((acc, item) => {
          const date = Object.keys(item)[0];
          acc[date] = item[date] || 0;
          return acc;
        }, {}),
      },
      {
        stat: "Total Number of Accounts added (Group)",
        ...data.accounts_added_group.reduce((acc, item) => {
          const date = Object.keys(item)[0];
          acc[date] = item[date] || 0;
          return acc;
        }, {}),
      },
      {
        stat: "Total Number of Accounts added (Personal)",
        ...data.accounts_added_personal.reduce((acc, item) => {
          const date = Object.keys(item)[0];
          acc[date] = item[date] || 0;
          return acc;
        }, {}),
      },
    ];

    return { data: transformed, dates: uniqueDates };
  };

  const transformBankData = (banks_data) => {
    const banksMap = {};
    const allBanks = new Set();
    const datesSet = new Set();
    // Extract all unique dates
    banks_data.forEach((item) => {
      Object.keys(item).forEach((date) => datesSet.add(date));
    });

    const uniqueDates = Array.from(datesSet);
    // Iterate over daily user activity data to populate the map
    banks_data.forEach((day) => {
      const date = Object.keys(day)[0];
      const banks = day[date];
      banks.forEach((data) => {
        const bankName = Object.keys(data)[0];
        const dict = data[bankName];
        allBanks.add(bankName);
        // Initialize the user's entry in the map if it doesn't exist
        if (!banksMap[bankName]) {
          banksMap[bankName] = {};
        }
        banksMap[bankName][date] = dict;
      });
    });

    // Prepare the final user activity data array
    const bankArray = Array.from(allBanks).map((bankName) => {
      const row = { bankName };
      uniqueDates.forEach((date) => {
        console.log(banksMap[bankName]?.[date]);
        row[date] =
          `${banksMap[bankName]?.[date]["email_count"]}, ${banksMap[bankName]?.[date]["bank_count"]}` ||
          "N/A"; // Default to 'N/A' if no data
      });
      return row;
    });

    setAllBankData([...allBanks]);

    return bankArray;
  };

  const transformUserActivityData = (dailyUserActivity) => {
    const userActivityMap = {};
    const allUsers = new Set();
    const datesSet = new Set();
    // Extract all unique dates
    dailyUserActivity.forEach((item) => {
      Object.keys(item).forEach((date) => datesSet.add(date));
    });

    const uniqueDates = Array.from(datesSet);

    // Iterate over daily user activity data to populate the map
    dailyUserActivity.forEach((day) => {
      const date = Object.keys(day)[0];
      const users = day[date];

      users.forEach((user) => {
        const userName = Object.keys(user)[0];
        const status = user[userName];

        allUsers.add(userName);
        // Initialize the user's entry in the map if it doesn't exist
        if (!userActivityMap[userName]) {
          userActivityMap[userName] = {};
        }
        userActivityMap[userName][date] = status;
      });
    });

    // Prepare the final user activity data array
    const userActivityArray = Array.from(allUsers).map((userName) => {
      const row = { userName };
      uniqueDates.forEach((date) => {
        row[date] = userActivityMap[userName]?.[date] || "N/A"; // Default to 'N/A' if no data
      });
      return row;
    });

    return userActivityArray;
  };

  const handleLeftArray = () => {
    setOffset((prevOffset) => Math.max(prevOffset - 1, 0)); // Decrease offset but not below 0
  };

  const handleRightArray = () => {
    setOffset((prevOffset) => prevOffset + 1); // Increase offset
  };

  const getSeverity = (status) => {
    switch (status) {
      case "Active":
        return "success";

      case "Inactive":
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData, options) => {
    return <Tag value={"*"} severity={getSeverity(rowData[options.field])} />;
  };

  const linkBodyTemplate = (rowData, options) => {
    const link = rowData[options.field];
    if (!link) {
      return (<p></p>);
    }
    return (<a href={rowData[options.field]} target="_blank" rel="noreferrer">View</a>);
  };

  return (
    <div className="container mt-3 mb-3" style={{ textAlign: "center" }}>
      {loading && (
        <div className="loader-container">
          <ProgressSpinner />
        </div>
      )}
      <div className="row mb-5 mt-3 g-1" style={{ flexWrap: "wrap" }}>
        {totalStats.map((totalStat, index) => (
          <div key={index} className="col">
            <Panel header={totalStat.title}>
              <p className="m-0">
                <h5>{totalStat.count}</h5>
              </p>
            </Panel>
          </div>
        ))}
      </div>
      <div className="row mb-3 mt-3">
        <div className="col">
          <h3>User Logs Statistic</h3>
        </div>
        <div className="col">
          <Button
            className="float-end mx-2"
            label="Next"
            icon="pi pi-arrow-right"
            onClick={handleRightArray}
          />
          <Button
            className="float-end"
            label="Previous"
            icon="pi pi-arrow-left"
            onClick={handleLeftArray}
            disabled={offset === 0}
          />
        </div>
      </div>
      <DataTable value={tableData} responsiveLayout="scroll">
        <Column field="stat" header="Statistic" />
        {dates.map((date, index) => (
          <Column key={index} field={date} header={date} />
        ))}
      </DataTable>
      <h3 className="mt-3 mb-3">Bank Auth Failed</h3>
      <DataTable value={bankAuthAccounts} tableStyle={{ minWidth: '50rem' }}>
          <Column field="user.full_name" header="Name"></Column>
          <Column field="bank_name" header="Bank Name"></Column>
          <Column field="processing_error" header="Error Message"></Column>
          <Column field="processing_started_at" header="Date Time"></Column>
          <Column field="error_screenshot_link" header="Snapshot" body={linkBodyTemplate}></Column>
      </DataTable>
      <h3 className="mt-3 mb-3">Bank Auth (Last 10)</h3>
      <DataTable value={bankAuthAccountsSuccess} tableStyle={{ minWidth: '50rem' }}>
          <Column field="user.full_name" header="Name"></Column>
          <Column field="bank_name" header="Bank Name"></Column>
          <Column field="timestamp" header="Date Time"></Column>
      </DataTable>
      <h3 className="mt-3 mb-3">User Activity Status</h3>
      <DataTable
        value={userActivityData}
        paginator
        rows={10}
        responsiveLayout="scroll"
        dataKey="userName"
      >
        <Column field="userName" header="User Name" />
        {dates.map((date, index) => (
          <Column
            key={index}
            field={date}
            header={date}
            body={statusBodyTemplate}
          />
        ))}
      </DataTable>
      <h3 className="mt-3 mb-3">Active Banks</h3>
      <div className="card flex justify-content-center"> 
        <ListBox options={allBankData} className="w-full md:w-14rem" />
      </div>

    </div>

  );
};

export default App;
