import axios from 'axios';

const API_URL = 'https://app.klick.money/api/v1'; // Replace with your actual API URL

export const fetchData = async (offset) => {
  try {
    const response = await axios.get(`${API_URL}/internal/stats/data?offset=${offset}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchTotalData = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/stats/total-stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchActiveTotalData = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/stats/active-accounts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchBankAuthSuccess = async () => {
  try {
    const response = await axios.get(`${API_URL}/internal/stats/bank-auths`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchAccounts = async () => {
  try {
    const response = await axios.get(`${API_URL}/finance/admin/accounts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};